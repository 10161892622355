















import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { loadFile } from "@/helpers/useLoader";

@Component
export default class SharedStoriesListItem extends Vue {
    @Prop() index!: number;
    @Prop() story!: any;

    public showItem: boolean = false;

    loadFile(path: string) {
        return loadFile(path);
    }

    @Emit("click:story")
    showStory(story: any) {
        return story;
    }

    mounted() {
        setTimeout(() => {
            this.showItem = true;
        }, 250 * (this.index + 1));
    }
}
