














































































import { Component, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import SharedProposalPdfViewer from "./PdfViewer.vue";
import { toDate } from "@/helpers/Formatter";
import SharedProposalStories from "./Stories.vue";
import { loadFile } from "@/helpers/useLoader";

@Component({
    components: {
        BaseCard,
        BaseAvatar,
        BaseLink,
        SharedProposalPdfViewer,
        SharedProposalStories,
    },
})
export default class SharedProposalDetail extends Vue {
    @Prop() proposal!: any;

    toDate(date: any) {
        return toDate(date);
    }

    loadFile(path: string) {
        return loadFile(path);
    }
}
