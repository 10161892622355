















import { loadFile } from "@/helpers/useLoader";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OthersDownloader extends Vue {
    @Prop() url!: string;
    @Prop({ default: "Download" }) buttonText!: string;
    @Prop() buttonClass!: string;
    @Prop() textClass!: string;
    @Prop() fileName!: string;
    @Prop({ default: "primary" }) color!: string;

    download(url: string) {
        const a: any = document.createElement("a");
        const fileName = this.fileName || url.split("/").pop();

        // a.href = require("@/../../storage/" + url);
        a.href = loadFile(url);

        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}
