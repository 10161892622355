

























import { Vue, Component, Prop } from "vue-property-decorator";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseDialog from "@/components/Base/BaseDialog.vue";
import SharedStoriesForm from "../Stories/Form.vue";
import SharedStoriesPreview from "../Stories/Preview.vue";
import SharedStoriesList from "../Stories/List.vue";

@Component({
    components: {
        BaseAvatar,
        BaseDialog,
        SharedStoriesForm,
        SharedStoriesPreview,
        SharedStoriesList,
    },
})
export default class SharedProposalStories extends Vue {
    @Prop() proposal!: any;

    public isLoading: boolean = false;
    public showDialog: boolean = false;
    public showOverlay: boolean = false;
    public storyData: any = {};
    public selectedStory: any = null;

    get stories() {
        return this.$store.state.stories.stories;
    }

    get me() {
        return this.$store.state.users.me;
    }

    get isCreator() {
        if (!this.me || !this.proposal) return false;

        const creatorId = this.me.id;
        const proposalId = this.proposal.user.id;

        return creatorId === proposalId;
    }

    newStory() {
        this.showDialog = true;
    }

    async createStory() {
        this.isLoading = true;

        this.storyData.proposalId = this.proposal.id;

        await this.$store.dispatch("stories/addStory", this.storyData);
        await this.$store.dispatch(
            "stories/getProposalStories",
            this.proposal.id
        );

        this.showDialog = false;
        this.isLoading = false;
    }

    async showStory(story: any) {
        this.selectedStory = story;
        this.showOverlay = true;
    }

    async deleteStory(story: any) {
        this.isLoading = true;

        await this.$store.dispatch("stories/deleteStory", story.id);
        await this.$store.dispatch(
            "stories/getProposalStories",
            this.proposal.id
        );

        this.showOverlay = false;
        this.isLoading = false;
    }
}
