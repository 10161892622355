var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('shared-proposal-stories',{attrs:{"proposal":_vm.proposal}}),(_vm.proposal)?_c('base-card',[_c('v-sheet',{attrs:{"max-height":"340","color":"grey lighten-2"}},[(_vm.proposal.cover_path)?_c('v-img',{staticClass:"rounded-t-lg",attrs:{"src":_vm.loadFile(_vm.proposal.cover_path),"max-height":"340"}}):_vm._e()],1),_c('v-layout',{staticClass:"pa-4 pb-0 py-md-6 px-md-12",attrs:{"column":""}},[_c('p',{staticClass:"text-h4 font-weight-bold",domProps:{"textContent":_vm._s(_vm.proposal.title)}}),_c('v-layout',{attrs:{"wrap":""}},[_c('base-link',{attrs:{"to":'/app/proposal?kategori=' + _vm.proposal.category.name}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"px-1 rounded",class:hover ? 'cursor-pointer' : '',attrs:{"color":"grey lighten-3"}},[_c('p',{staticClass:"mb-0 body-2",class:hover
                                        ? 'text--primary'
                                        : 'text--secondary',domProps:{"textContent":_vm._s('#' + _vm.proposal.category.name)}})])]}}],null,false,2817733441)})],1)],1),_c('v-layout',{staticClass:"mt-3 align-center",attrs:{"wrap":""}},[_c('base-avatar',{staticClass:"mr-2",attrs:{"size":"32","user":_vm.proposal.user}}),_c('base-link',{attrs:{"to":'/app/pengusul/' + _vm.proposal.user.id}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var hover = ref.hover;
return [_c('span',{staticClass:"mr-4 font-weight-bold",class:hover
                                    ? 'text-decoration-underline cursor-pointer'
                                    : '',domProps:{"textContent":_vm._s(_vm.proposal.user.profile.display_name)}})]}}],null,false,3351315370)})],1),_c('span',{staticClass:"text--disabled",domProps:{"textContent":_vm._s(_vm.toDate(_vm.proposal.created_at))}})],1),_c('p',{staticClass:"my-4",domProps:{"textContent":_vm._s(_vm.proposal.description)}}),_c('v-sheet',{staticClass:"mb-6 mx-n4 mx-sm-0",attrs:{"min-height":"50vh","color":"white"}},[_c('shared-proposal-pdf-viewer',{attrs:{"pdfPath":_vm.proposal.proposal_path,"fileName":'Proposal - ' + _vm.proposal.title}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }