























































































































import BaseLink from "../../Base/BaseLink.vue";
import BaseDialog from "../../Base/BaseDialog.vue";
import BaseAvatar from "../../Base/BaseAvatar.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { toDisplayName, toDateAndTime } from "@/helpers/Formatter";
import { loadFile } from "@/helpers/useLoader";

@Component({
    components: {
        BaseAvatar,
        BaseDialog,
        BaseLink,
    },
})
export default class SharedStoriesPreview extends Vue {
    @Prop() value!: boolean;
    @Prop() story!: any;
    @Prop() proposal!: any;

    public showDialog: boolean = false;

    get hasImage() {
        return this.story && this.story.file_path;
    }

    get showOverlay() {
        return this.value;
    }
    set showOverlay(newValue: boolean) {
        this.$emit("input", newValue);
    }

    get me() {
        return this.$store.state.users.me;
    }

    loadFile(path: string) {
        return loadFile(path);
    }

    toDisplayName(user: any) {
        return toDisplayName(user);
    }

    toDateAndTime(date: any) {
        return toDateAndTime(date);
    }

    deleteStory() {
        this.$emit("delete:story", this.story);
        this.showDialog = false;
    }
}
