
































import { Vue, Component, Prop } from "vue-property-decorator";
import BaseDialog from "@/components/Base/BaseDialog.vue";
import BaseForm from "@/components/Base/BaseForm.vue";

@Component({
    components: {
        BaseDialog,
        BaseForm,
    },
})
export default class SharedStoriesForm extends Vue {
    @Prop() value!: boolean;
    @Prop() data!: any;
    @Prop() loading!: any;

    public isFormValid: boolean = false;

    public forms: any[] = [
        {
            text: "Upload foto",
            placeholder: "Pilih foto",
            value: "story",
            withLabel: true,
            type: "image",
            required: true,
            hidePreview: true,
        },
        {
            text: "Caption",
            placeholder: "Caption / deskripsi foto",
            value: "caption",
            withLabel: true,
            required: true,
        },
    ];

    get isImageSelected() {
        return this.data && this.data.story;
    }

    get imgSrc() {
        let src = "#";

        if (this.isImageSelected) src = URL.createObjectURL(this.data.story);

        return src;
    }

    get showDialog() {
        return this.value;
    }
    set showDialog(newValue: boolean) {
        this.$emit("input", newValue);
    }

    checkFormValidation(isValid: boolean) {
        this.isFormValid = isValid;
    }

    createStory() {
        this.$emit("add:story");
    }
}
