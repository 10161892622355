




































import BaseCard from "../../Base/BaseCard.vue";
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import SharedStoriesListItem from "./ListItem.vue";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";

@Component({
    components: {
        SharedStoriesListItem,
        BaseAvatar,
        BaseCard,
    },
})
export default class SharedStoriesList extends Vue {
    @Prop() stories!: any[];
    @Prop() isCreator!: boolean;

    get me() {
        return this.$store.state.users.me;
    }

    @Emit("click:story")
    showStory(story: any) {
        return story;
    }

    @Emit("add:story")
    newStory() {
        return;
    }
}
