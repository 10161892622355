































































































import OthersDownloader from "@/components/Others/Downloader.vue";
import { loadFile } from "@/helpers/useLoader";
import { Component, Prop, Vue } from "vue-property-decorator";
// import "pdfjs-dist/build/pdf.worker.entry";
const pdf = require("pdfvuer");

@Component({
    components: {
        pdf,
        OthersDownloader,
    },
})
export default class SharedProposalPdfViewer extends Vue {
    @Prop() pdfPath!: string;
    @Prop() fileName!: string;

    public page: number = 1;
    public numPages: number = 0;
    public pdfdata: any = undefined;
    public errors: any[] = [];
    public scale: string = "page-width";

    public showController: boolean = true;
    public isPdfLoaded: boolean = false;

    loadFile(path: string) {
        return loadFile(path);
    }

    pdfLoaded(isLoading: boolean) {
        setTimeout(() => {
            this.isPdfLoaded = !isLoading;
        }, 100);
    }

    prevPage() {
        this.page--;
    }

    nextPage() {
        this.page++;
    }

    // get formattedZoom() {
    //     return Number.parseInt(this.scale * 100);
    // }
}
